/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import { setPage, setRowsPerPage } from "../../../redux/slices/pagination";
import { useGetResourceCountQuery } from "../../../api/endpoints/user";

import useWebSocket from "react-use-websocket";

const dt = new Date().getTime().toString();

export default function TablePaginationComponent({
  data_size,
  refetch,
  resource,
  loading,
  filters,
}) {
  const dispatch = useDispatch();

  let tmp = `ws://127.0.0.1:4000/?userId=agent_` + dt;
  let perm = `wss://dolphin-app-xv565.ondigitalocean.app?userId=agent_` + dt;

  const { lastJsonMessage } = useWebSocket(encodeURI(perm), {
    shouldReconnect: true,
    retryOnError: true,
  });

  React.useEffect(() => {
    if (lastJsonMessage !== null) {
      if (
        lastJsonMessage.verb === "add" &&
        lastJsonMessage.resource === resource
      ) {
        refetch();
      }
    }
  }, [lastJsonMessage]);

  const { rowsPerPage = 10, page = 0 } = useSelector(
    (state) => state.pagination
  )[resource];

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setRowsPerPage({
        target: resource,
        rowsPerPage: parseInt(event.target.value),
      })
    );
    dispatch(setPage({ target: resource, page: 0 }));
    refetch();
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ target: resource, page: newPage }));
    refetch();
  };

  const {
    data = null,
    isLoading,
    isError,
    isFetching,
    refetch: reloadCount,
  } = useGetResourceCountQuery({ resource, filters });

  React.useEffect(() => {
    if (data) {
      if (data.count <= page * rowsPerPage && page !== 0) {
        dispatch(setPage({ target: resource, page: 0 }));
      }
    }
  }, [data, data_size]);

  React.useEffect(() => {
    reloadCount();
  }, [data_size, resource, loading, filters]);

  return (
    <TablePagination
      component="div"
      count={data && data.resource === resource ? data.count : 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
