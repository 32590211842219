import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetEventPromotersQuery } from "../../api/endpoints/promoter";
import { useSelector } from "react-redux";
import Row from "./table/row";
import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Promoters() {
  const [type, setType] = React.useState("unverified");

  const { rowsPerPage, page } = useSelector((state) => state.pagination).venue;

  const {
    data: eventPromoters = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetEventPromotersQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    verificationStatus: type === "verified" ? "approved" : "pending",
  });

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>Promoters</h2>
          <RadioGroup
            defaultValue={type}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              refetch();
            }}
          >
            <Stack sx={{ alignItems: "center" }} direction="row">
              <Radio color="info" value="unverified" />
              <Typography variant="caption">Unverified</Typography>
              <Radio color="info" sx={{ ml: 2 }} value="verified" />
              <Typography variant="caption">Verified</Typography>

              <IconButton
                disabled={isFetching || isLoading}
                sx={{ ml: 2 }}
                onClick={refetch}
              >
                <IoMdRefresh />
              </IconButton>
            </Stack>
          </RadioGroup>
          <TablePaginationComponent
            filters={{
              verificationStatus: type === "verified" ? "approved" : "pending",
            }}
            loading={isLoading || isFetching}
            data_size={eventPromoters.length}
            refetch={refetch}
            resource="venue"
          />
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {eventPromoters.map((item, index) => {
              return (
                <Row
                  eventPromoter={item}
                  index={index}
                  length={eventPromoters.length}
                  key={item._id}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
}
