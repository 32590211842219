import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Booking from "./modal/view";
import { ButtonBase, Chip } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({
  booking,
  index,
  length,
  setOpen,
  setBookingId,
  type,
}) {
  let isVenue = booking.client?.venue ? true : false;
  let client = booking?.client;
  let name = isVenue
    ? client.venue?.name
    : client?.firstName + " " + client?.lastName;

  const checked = true;

  // rentalMode, acceptedDates, rate, days, hours
  // equipment | hourly rental mode = acceptedDates * hours * rate
  // equipment | daily rate = accepted dates * rate
  // service =  acceptedDates * rate

  let {
    acceptedDates,
    rate,
    appliedCommission,
    service,
    equipment,
    rentalMode,
    transport,
    hours,
  } = booking;

  // (rate * hours + transport) * accepted dates

  let total = React.useMemo(() => {
    return equipment && rentalMode === "hour"
      ? (rate * hours + transport) * acceptedDates.length
      : (rate + transport) * acceptedDates.length;
  }, [acceptedDates.length, equipment, hours, rate, rentalMode, transport]);

  let commission = React.useMemo(() => {
    return (
      (equipment && rentalMode === "hour"
        ? rate * hours * acceptedDates.length
        : rate * acceptedDates.length) *
      (appliedCommission / 100)
    );
  }, [
    acceptedDates.length,
    appliedCommission,
    equipment,
    hours,
    rate,
    rentalMode,
  ]);

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <ButtonBase
          key={booking._id}
          onClick={() => {
            setBookingId(booking._id);
            setOpen(true);
          }}
          sx={{
            mt: 1,
            textAlign: "left",
          }}
        >
          <Stack
            sx={{
              //   boxShadow: "5px 5px 24px 1px rgba(123, 157, 194, 0.1)",
              borderRadius: 0,

              background: "#1B1B1B",
              borderWidth: 0,
              borderLeftWidth: 1,
              borderStyle: "solid",
              alignItems: "center",
              flex: 1,
              p: 2,
            }}
            direction="row"
          >
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecorationLine: "underline",
                  },
                }}
              >
                {booking.bookingNumber} <span> - </span>
                {index + 1} of {length}
              </Typography>
              <Typography variant="caption">{name}</Typography>
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>${numberWithCommas(total.toFixed(2))}</Typography>
            </Stack>
            {type !== "unpaid" ? (
              <Stack sx={{ flex: 1, pr: 2 }}>
                <Typography>
                  ${numberWithCommas(commission.toFixed(2))}
                </Typography>
              </Stack>
            ) : (
              <Stack sx={{ flex: 1, pr: 2 }}>
                {booking.proofOfPayment ? (
                  <Chip
                    sx={{ alignSelf: "flex-start" }}
                    color="warning"
                    label="POP Uploaded"
                  />
                ) : (
                  <Chip
                    sx={{ alignSelf: "flex-start" }}
                    label="Pending Payment"
                  />
                )}
              </Stack>
            )}
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            >
              <Typography>
                <span>{service && "Entertainment"}</span>
                <span>{equipment && "Equipment"}</span>
              </Typography>
            </Stack>
          </Stack>
        </ButtonBase>
      </Fade>
    </>
  );
}
