/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Stack from "@mui/material/Stack";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { HiMinus } from "react-icons/hi2";

const CustomListButton = styled((props) => <ListItemButton {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    "&.Mui-selected": {
      color: "#139E43",
      backgroundColor: "#EAF7EE",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#144C2B",
    },
  })
);

const CustomListButton2 = styled((props) => <ListItemButton {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    "&.Mui-selected": {
      color: "#139E43",
      backgroundColor: "white",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#144C2B",
    },
  })
);

export default function MenuItem({ data, handleChange, value }) {
  const [open, setOpen] = React.useState(
    data.subItems && value.includes(data.id)
  );

  React.useEffect(() => {
    if (data.subItems && !value.includes(data.id)) {
      setOpen(false);
    }
  }, [value]);

  const handleClick = () => {
    setOpen(!open);
  };
  if (data.subItems) {
    return (
      <Stack>
        <ListItem sx={{ mt: 0.75 }} key={data.id} disablePadding>
          <CustomListButton
            selected={value.includes(data.id)}
            onClick={() => {
              setOpen(true);
            }}
            sx={{ mr: 1, ml: 1, borderRadius: 2 }}
          >
            <ListItemIcon
              sx={{ color: value.includes(data.id) ? "#139E43" : "black" }}
            >
              {data.icon}
            </ListItemIcon>
            <ListItemText primary={data.title} />
            {data.subItems && (
              <div> {open ? <ExpandLess /> : <ExpandMore />}</div>
            )}
          </CustomListButton>
        </ListItem>
        {data.subItems && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {data.subItems.map((e) => {
                return (
                  <Link to={data.id + "/" + e.id}>
                    <CustomListButton2
                      selected={
                        e.id === ""
                          ? value.includes(data.id) && value[2] === ""
                          : value.includes(data.id) && value.includes(e.id)
                      }
                      sx={{ pl: 4, mt: 0.75, mr: 1, ml: 1, borderRadius: 2 }}
                    >
                      <ListItemIcon>
                        <ListItemIcon
                          sx={{
                            color:
                              (e.id === "" &&
                                value.includes(data.id) &&
                                value[2] === "") ||
                              (value.includes(data.id) && value.includes(e.id))
                                ? "#139E43"
                                : "black",
                          }}
                        >
                          <HiMinus />
                        </ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary={e.title} />
                    </CustomListButton2>
                  </Link>
                );
              })}
            </List>
          </Collapse>
        )}
      </Stack>
    );
  } else {
    return (
      <Link to={data.id}>
        <ListItem sx={{ mt: 1 }} key={data.id} disablePadding>
          <ListItemButton
            selected={value.includes(data.id)}
            sx={{ mr: 1, ml: 1, borderRadius: 2 }}
          >
            <ListItemIcon>{data.icon}</ListItemIcon>
            <ListItemText primary={data.title} />
          </ListItemButton>
        </ListItem>
      </Link>
    );
  }
}
