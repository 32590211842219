import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Booking from "./modal/view";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({ booking, index, length }) {
  let isVenue = booking.client.venue ? true : false;
  let client = booking.client;
  let name = isVenue
    ? client.venue.name
    : client.firstName + " " + client.lastName;

  let total = booking.cart.reduce(function (prev, current) {
    let result = 0;
    if (current.days) {
      result = current.days * current.rate;
    } else {
      result = current.hours * current.rate;
    }

    //result += result * (current.commission / 100);
    result += current.transport;
    result = result * current.dates.length;
    return prev + result;
  }, 0);

  let commission = booking.cart.reduce(function (prev = 0, current) {
    let result = 0;
    if (current.days) {
      result = current.days * current.rate;
    } else {
      result = current.hours * current.rate;
    }

    let com = result * (current.commission / 100) * current.dates.length;
    return prev + com;
  }, 0);

  let services = 0;
  let equipment = 0;

  booking.cart.forEach((item) => {
    item.service && services++;
    item.equipment && equipment++;
  });

  const checked = true;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <Stack
          key={booking._id}
          sx={{
            //   boxShadow: "5px 5px 24px 1px rgba(123, 157, 194, 0.1)",
            borderRadius: 0,
            mt: 1,
            p: 2,
            background: "#1B1B1B",
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: "solid",
            alignItems: "center",
          }}
          direction="row"
        >
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography
              onClick={() => {
                setOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              {booking.bookingNumber}
            </Typography>
            <Typography variant="caption">
              {index + 1} of {length}
            </Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>${numberWithCommas(total.toFixed(2))}</Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>${numberWithCommas(commission.toFixed(2))}</Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>
              <span>{services}</span> in entertainers
              {" &"} <span>{equipment}</span> in equipment
            </Typography>
          </Stack>
        </Stack>
      </Fade>
      {open && <Booking open={open} _id={booking._id} setOpen={setOpen} />}
    </>
  );
}
