import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiClock, HiCheckCircle } from "react-icons/hi2";
import Profile from "./profile";
import moment from "moment";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Cart({ booking }) {
  let { rentalMode, hours, equipment, service, rate, transport } = booking;
  const calculateTotal = (item) => {
    let total = 0;

    return total;
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack sx={{ background: "#353535", p: 2, borderRadius: 2 }}>
        <Stack>
          <Typography>
            <b>{booking.service ? "Service" : "Equipment"}</b>
          </Typography>
          <Stack sx={{ mt: 1 }}>
            {booking.acceptedDates.map((dateStr) => {
              let date = moment(dateStr).toDate();
              let subtotal =
                equipment && rentalMode === "hour" ? rate * hours : rate;

              let currDate = new Date();
              let newBooking = currDate <= date;

              return (
                <Stack sx={{ mb: 1 }}>
                  <Stack sx={{ alignItems: "center" }} direction="row">
                    {newBooking ? (
                      <HiClock />
                    ) : (
                      <HiCheckCircle color="#00AA33" />
                    )}
                    <Typography sx={{ ml: 1 }}>
                      {moment(date).format("D MMM YYYY")} -{" "}
                      {booking.days
                        ? booking.days + " day(s)"
                        : booking.hours + " hour(s)"}{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle2">
                    - Service charge: ${numberWithCommas(subtotal.toFixed(2))}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Commission: $
                    {numberWithCommas(
                      ((booking.appliedCommission / 100) * subtotal).toFixed(2)
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Payout: $
                    {numberWithCommas(
                      (
                        subtotal -
                        (booking.appliedCommission / 100) * subtotal
                      ).toFixed(2)
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Transport: ${numberWithCommas(transport.toFixed(2))}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>

          <Profile profile={(booking.service || booking.equipment)?.listedBy} />
        </Stack>
      </Stack>
    </Stack>
  );
}
