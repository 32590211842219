import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, verified: "true" };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVenues: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/users/venue/?limit=${options.limit}&skip=${options.skip}&verificationStatus=${options.verificationStatus}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Venue", id: _id })),
        { type: "Venue", id: "LIST" },
      ],
    }),
    getVenue: builder.query({
      query: (userID) => {
        return { url: `/users/single/${userID}`, method: "GET" };
      },
    }),
    removeVenue: builder.mutation({
      query: (userID) => {
        return { url: `/users/${userID}`, method: "DELETE" };
      },
      invalidatesTags: [{ type: "Venue", id: "LIST" }],
    }),

    editVenue: builder.mutation({
      query: (data) => ({
        url: `/users/admin/${data._id}`,
        method: "PATCH",
        body: data.user,
      }),
      invalidatesTags: [{ type: "Venue", id: "LIST" }],
      async onQueryStarted({ _id, venue }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedVenue } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getVenues", params, (draft) => {
              const venue = draft.find((e) => e._id === updatedVenue._id);
              if (venue) {
                Object.keys(updatedVenue).forEach((key) => {
                  venue[key] = updatedVenue[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getVenue", _id, (draft) => {
              Object.assign(draft, updatedVenue);
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVenuesQuery,
  useGetVenueQuery,
  useEditVenueMutation,
  useRemoveVenueMutation,
} = extendedApi;
