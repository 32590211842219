import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "./modal/view";
import Chip from "@mui/material/Chip";
import { ButtonBase } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({ equipment, index, length }) {
  const checked = true;

  const [open, setOpen] = React.useState(false);

  const { title, category, listedBy, rate, rentalMode } = equipment;

  let { firstName, lastName, representative, businessName } =
    listedBy?.entertainer;

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <ButtonBase
          onClick={() => {
            setOpen(true);
          }}
          sx={{ mt: 1, textAlign: "left" }}
        >
          <Stack
            key={equipment._id}
            sx={{
              borderRadius: 0,
              flex: 1,
              p: 2,
              background: "#1B1B1B",
              borderWidth: 0,
              borderLeftWidth: 1,
              borderStyle: "solid",
              alignItems: "center",
            }}
            direction="row"
          >
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecorationLine: "underline",
                  },
                }}
              >
                {title}
              </Typography>
              <Typography variant="caption">
                {index + 1} of {length}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Chip sx={{ alignSelf: "start" }} label={category.title} />
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                {representative ? businessName : firstName + " " + lastName}
              </Typography>
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                ${numberWithCommas(rate.toFixed(2))}/{rentalMode}
              </Typography>
            </Stack>
          </Stack>
        </ButtonBase>
      </Fade>
      {open && <Modal open={open} _id={equipment._id} setOpen={setOpen} />}
    </>
  );
}
