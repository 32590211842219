import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/events/?limit=${options.limit}&skip=${options.skip}&type=${options.type}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Event", id: _id })),
        { type: "Event", id: "LIST" },
      ],
    }),
    getEvent: builder.query({
      query: (eventID) => {
        return { url: `/events/${eventID}`, method: "GET" };
      },
    }),

    deleteEvent: builder.mutation({
      query: (_id) => ({
        url: `/events/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetEventsQuery, useGetEventQuery, useDeleteEventMutation } =
  extendedApi;
