import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiMapPin, HiPhone } from "react-icons/hi2";
import { MdEmail } from "react-icons/md";

export default function Profile({ profile }) {
  let name;
  let businessName;
  let representative;
  let accountType;

  if (
    profile.entertainer?.businessName ||
    profile.entertainer?.representative
  ) {
    businessName = profile.entertainer.businessName;
    accountType = "Business Account";
    representative =
      profile.entertainer?.representative?.firstName +
      " " +
      profile.entertainer?.representative?.lastName;
  } else {
    accountType = "Independent Provider";
    name =
      (profile?.firstName || profile.entertainer?.firstName) +
      " " +
      (profile?.lastName || profile.entertainer?.lastName);
  }
  return (
    <Stack>
      <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
      <Typography>
        <b>Service provider profile</b>
      </Typography>
      <Stack sx={{ mt: 1 }}>
        {accountType === "Business Account" && (
          <>
            <Typography>{businessName}</Typography>
            <Typography variant="caption">
              Representative: {representative}
            </Typography>
          </>
        )}
        {accountType === "Independent Provider" && (
          <>
            <Typography>{name}</Typography>
          </>
        )}
      </Stack>
      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
        <Chip
          size="small"
          sx={{ alignSelf: "start", mt: 0.5 }}
          label={accountType}
        />
        <Chip
          icon={<HiMapPin size={14} />}
          size="small"
          sx={{ alignSelf: "start", ml: 0.5, mt: 0.5 }}
          label={profile.entertainer?.city}
        />
        <Chip
          icon={<HiPhone size={14} />}
          size="small"
          sx={{ alignSelf: "start", ml: 0.5, mt: 0.5 }}
          label={profile.phone}
        />
        <Chip
          icon={<MdEmail size={14} />}
          size="small"
          sx={{ alignSelf: "start", ml: 0.5, mt: 0.5 }}
          label={profile.email}
        />
      </Stack>
    </Stack>
  );
}
