import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import TableHeader from "./table/header";
import Grow from "@mui/material/Grow";
import Row from "./table/row";
import { useFormik } from "formik";
import { useGetUsersQuery } from "../../api/endpoints/user";
import { HiPlus } from "react-icons/hi2";
import { useAddNewUserMutation } from "../../api/endpoints/user";

const styles = {
  inputWrapper: {
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "16px",
    color: "#000",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "16px",
    "&::placeholder": {
      color: "#888",
    },
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "16px",
  },
  error: {
    color: "#d32f2f",
    marginTop: "4px",
  },
};

const AddOrganizationDialog = ({ open, onClose }) => {
  const [addNewUser, { isLoading }] = useAddNewUserMutation();
  const formik = useFormik({
    initialValues: {
      organizationName: "",
      password: "",
      confirmation: "",
      phoneNumber: "",
      email: "",
      businessNature: "venue", // Default value
    },
    validationSchema: Yup.object({
      organizationName: Yup.string().required("Organization name is required"),
      password: Yup.string()
        .required("Password is required")
        .min(7, "Password must be at least 7 characters"),
      confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirmation is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      businessNature: Yup.string()
        .oneOf(
          ["venue", "corporate", "eventPromoter", "entertainer"],
          "Invalid business nature"
        )
        .required("Business nature is required"),
    }),
    onSubmit: async (values) => {
      try {
        let result = await addNewUser({
          ...values,
          isParentAccount: true,
        }).unwrap();
        if (result?.error?.data?.error) {
          alert(result.error.data.error);
          return;
        }
        formik.resetForm(); // Reset form after successful save
        onClose(); // Close dialog on successful save
      } catch (error) {
        alert(error?.data?.error);
        console.error("Failed to save user:", error);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: { backgroundColor: "#fff", color: "black", borderRadius: 3 },
      }}
    >
      <DialogTitle>Add New Organization Master Account</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={styles.inputWrapper}>
            <label htmlFor="organizationName" style={styles.label}>
              Organization Name
            </label>
            <input
              id="organizationName"
              style={styles.input}
              placeholder="Enter organization name"
              name="organizationName"
              value={formik.values.organizationName}
              onChange={formik.handleChange}
            />
            {formik.touched.organizationName &&
              formik.errors.organizationName && (
                <div style={styles.error}>{formik.errors.organizationName}</div>
              )}
          </Box>

          <Box sx={styles.inputWrapper}>
            <label htmlFor="password" style={styles.label}>
              Default Password
            </label>
            <input
              id="password"
              type="password"
              style={styles.input}
              placeholder="Enter password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password && (
              <div style={styles.error}>{formik.errors.password}</div>
            )}
          </Box>

          <Box sx={styles.inputWrapper}>
            <label htmlFor="confirmation" style={styles.label}>
              Confirm Password
            </label>
            <input
              id="confirmation"
              type="password"
              style={styles.input}
              placeholder="Confirm password"
              name="confirmation"
              value={formik.values.confirmation}
              onChange={formik.handleChange}
            />
            {formik.touched.confirmation && formik.errors.confirmation && (
              <div style={styles.error}>{formik.errors.confirmation}</div>
            )}
          </Box>

          <Box sx={styles.inputWrapper}>
            <label htmlFor="phoneNumber" style={styles.label}>
              Phone Number
            </label>
            <input
              id="phoneNumber"
              style={styles.input}
              placeholder="Enter phone number"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div style={styles.error}>{formik.errors.phoneNumber}</div>
            )}
          </Box>

          <Box sx={styles.inputWrapper}>
            <label htmlFor="email" style={styles.label}>
              Email Address
            </label>
            <input
              id="email"
              type="email"
              style={styles.input}
              placeholder="Enter email address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div style={styles.error}>{formik.errors.email}</div>
            )}
          </Box>

          <Box sx={styles.inputWrapper}>
            <label htmlFor="businessNature" style={styles.label}>
              Business Nature
            </label>
            <select
              id="businessNature"
              style={styles.select}
              name="businessNature"
              value={formik.values.businessNature}
              onChange={formik.handleChange}
            >
              <option value="venue">Venue</option>
              <option value="corporate">Corporate</option>
              <option value="eventPromoter">Event Promoter</option>
              <option value="entertainer">Entertainer</option>
            </select>
            {formik.touched.businessNature && formik.errors.businessNature && (
              <div style={styles.error}>{formik.errors.businessNature}</div>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={16} /> : null}
          onClick={formik.handleSubmit}
          color="success"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function Users() {
  const { data: users = [], isLoading, isFetching } = useGetUsersQuery();

  const sortedUsers = [...users].sort((a, b) => {
    let nameA = a.firstName
      ? `${a.firstName} ${a.lastName}`
      : a.organizationName;
    let nameB = b.firstName
      ? `${b.firstName} ${b.lastName}`
      : b.organizationName;
    return nameA.localeCompare(nameB);
  });

  /**
   * {
   // limit: rowsPerPage,
   // skip: page * rowsPerPage,
  }
   */

  const checked = true;

  const [addOrgOpen, setAddOrgOpen] = React.useState(false);

  const [activeUser, setActiveUser] = React.useState(null);

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
          },
          mt: 2,
          mr: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
          },
          height: "100vh",
          pb: 16,
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack>
            <h2 style={{ margin: 0 }}>End-Users</h2>
          </Stack>
          <Button
            onClick={() => {
              setAddOrgOpen(true);
            }}
            startIcon={<HiPlus />}
            sx={{
              textTransform: "none",
              borderRadius: 32,
            }}
          >
            New Organisation
          </Button>
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {sortedUsers.map((item, index) => {
              return (
                <Row
                  user={item}
                  index={index}
                  length={users.length}
                  key={item._id}
                />
              );
            })}
          </Stack>
        </Stack>
        <AddOrganizationDialog
          open={addOrgOpen}
          onClose={() => {
            setAddOrgOpen(false);
          }}
        />
      </Box>
    </Grow>
  );
}
