import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, verified: "true" };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEntertainers: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/users/entertainers/?limit=${options.limit}&skip=${options.skip}&verificationStatus=${options.verificationStatus}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Entertainer", id: _id })),
        { type: "Entertainer", id: "LIST" },
      ],
    }),
    removeEntertainer: builder.mutation({
      query: (userID) => {
        return { url: `/users/${userID}`, method: "DELETE" };
      },
      invalidatesTags: [{ type: "Entertainer", id: "LIST" }],
    }),
    getEntertainer: builder.query({
      query: (userID) => {
        return { url: `/users/single/${userID}`, method: "GET" };
      },
    }),

    editEntertainer: builder.mutation({
      query: (data) => ({
        url: `/users/admin/${data._id}`,
        method: "PATCH",
        body: data.user,
      }),
      invalidatesTags: [{ type: "Entertainer", id: "LIST" }],
      async onQueryStarted({ _id, entertainer }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedEntertainer } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              "getEntertainers",
              params,
              (draft) => {
                const entertainer = draft.find(
                  (e) => e._id === updatedEntertainer._id
                );
                if (entertainer) {
                  Object.keys(updatedEntertainer).forEach((key) => {
                    entertainer[key] = updatedEntertainer[key];
                  });
                }
              }
            )
          );
          dispatch(
            apiSlice.util.updateQueryData("getEntertainer", _id, (draft) => {
              Object.assign(draft, updatedEntertainer);
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEntertainersQuery,
  useGetEntertainerQuery,
  useEditEntertainerMutation,
  useRemoveEntertainerMutation,
} = extendedApi;
