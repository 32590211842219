import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, approved: false };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/services/?limit=${options.limit}&skip=${options.skip}&approved=${options.approved}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Service", id: _id })),
        { type: "Service", id: "LIST" },
      ],
    }),
    getService: builder.query({
      query: (serviceID) => {
        return { url: `/services/${serviceID}`, method: "GET" };
      },
    }),
    getServiceResources: builder.query({
      query: () => {
        return { url: `/resources/service`, method: "GET" };
      },
    }),
    addNewService: builder.mutation({
      query: (service) => ({
        url: "/services",
        method: "POST",
        body: service,
      }),
      invalidatesTags: [{ type: "Service", id: "LIST" }],
    }),
    editService: builder.mutation({
      query: (data) => ({
        url: `/services/${data._id}`,
        method: "PATCH",
        body: data.service,
      }),
      async onQueryStarted({ _id, service }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedService } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getServices", params, (draft) => {
              const service = draft.find((e) => e._id === updatedService._id);
              if (service) {
                Object.keys(updatedService).forEach((key) => {
                  service[key] = updatedService[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getService", _id, (draft) => {
              Object.assign(draft, updatedService);
            })
          );
        } catch {}
      },
      invalidatesTags: [{ type: "Service", id: "LIST" }],
    }),
    editServiceUpdate: builder.mutation({
      query: (data) => ({
        url: `/services/${data._id}`,
        method: "PATCH",
        body: data.service,
      }),
      async onQueryStarted({ _id, service }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedService } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getServices", params, (draft) => {
              const service = draft.find((e) => e._id === updatedService._id);
              if (service) {
                Object.keys(updatedService).forEach((key) => {
                  service[key] = updatedService[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getService", _id, (draft) => {
              Object.assign(draft, updatedService);
            })
          );
        } catch {}
      },
    }),
    deleteService: builder.mutation({
      query: (_id) => ({
        url: `/services/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Service", id: "LIST" }],
    }),
    deleteServices: builder.mutation({
      query: (data) => ({
        url: `/services/many`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [{ type: "Service", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetServicesQuery,
  useGetServiceQuery,
  useGetServiceResourcesQuery,
  useAddNewServiceMutation,
  useEditServiceMutation,
  useDeleteServiceMutation,
  useDeleteServicesMutation,
  useEditServiceUpdateMutation,
} = extendedApi;
