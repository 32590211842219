import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiMapPin } from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import { constants } from "../../../../config/constants";
import Link from "@mui/material/Link";
import { useGetEventQuery } from "../../../../api/endpoints/event";

export default function Event_({ open, setOpen, _id }) {
  const {
    data: event_ = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetEventQuery(_id);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Event details</DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {event_ && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Typography sx={{ mb: 1, color: "#CECECE" }}>
                  <b>About Event</b>
                </Typography>
                <Stack>
                  <Typography>
                    <b>{event_.title}</b>{" "}
                  </Typography>
                  <Typography>
                    <b>Description:</b> {event_.description}
                  </Typography>
                  <Typography>
                    <b>Date:</b> {new Date(event_.date).getDate()}{" "}
                    {months[new Date(event_.date).getMonth()]}{" "}
                    {new Date(event_.date).getFullYear()}
                  </Typography>

                  <Stack sx={{ mt: 0 }} direction="row">
                    <HiMapPin style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>
                        {event_.location?.address ||
                          event_.locationEmbed?.address}
                      </Typography>
                      <Typography>
                        Venue Number:{" "}
                        {event_.location?.venueNumber ||
                          event_.locationEmbed?.venueNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Listed By</b>
              </Typography>

              {event_.listedBy && (
                <>
                  <Stack sx={{ mt: 1 }}>
                    <Stack>
                      <Typography>
                        <b>
                          {event_.listedBy.venue?.name ||
                            event_.listedBy.eventPromoter?.businessName ||
                            `${event_.listedBy.eventPromoter?.firstName} ${event_.listedBy?.eventPromoter?.lastName}`}
                        </b>
                      </Typography>
                    </Stack>
                    <Chip
                      size="small"
                      color="info"
                      sx={{ alignSelf: "start" }}
                      label={
                        event_?.listedBy?.venue ? "venue" : "event promoter"
                      }
                    />
                    {(event_.listedBy.venue?.representative ||
                      event_.listedBy.eventPromoter?.representative) && (
                      <Typography>
                        Representative:{" "}
                        {
                          (
                            event_.listedBy.venue ||
                            event_.listedBy.eventPromoter
                          ).representative.firstName
                        }{" "}
                        {
                          (
                            event_.listedBy.venue ||
                            event_.listedBy.eventPromoter
                          ).representative.lastName
                        }
                      </Typography>
                    )}
                  </Stack>

                  <Stack sx={{ mt: 2 }} direction="row">
                    <HiPhone style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>{event_.listedBy.phone}</Typography>
                    </Stack>
                  </Stack>
                </>
              )}
              {event_.tickets && (
                <>
                  <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
                  <Typography sx={{ mb: 1, color: "#CECECE" }}>
                    <b>Tickets</b>
                  </Typography>

                  {event_.tickets.map((ticket) => {
                    return (
                      <Stack
                        sx={{
                          borderRadius: 4,
                          p: 2,
                          background: "#191618",
                          mb: 0.1,
                        }}
                      >
                        <Stack spacing={4} direction="row">
                          <Stack sx={{ flex: 1 }}>
                            <Typography variant="caption">
                              Ticket name
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              {ticket.ticketName}
                            </Typography>
                          </Stack>
                          <Stack sx={{ flex: 1 }}>
                            <Typography variant="caption">Quantity</Typography>
                            <Typography sx={{ mb: 1 }}>
                              {ticket.quantity}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack spacing={4} direction="row">
                          <Stack sx={{ flex: 1 }}>
                            <Typography variant="caption">Price</Typography>
                            <Typography sx={{ mb: 1 }}>
                              ${ticket.price.toFixed(2)}
                            </Typography>
                          </Stack>
                          <Stack sx={{ flex: 1 }}>
                            <Typography variant="caption">Sold</Typography>
                            <Typography sx={{ mb: 1 }}>
                              {ticket.sold} | $
                              {(ticket.price * ticket.sold).toFixed(2)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })}
                </>
              )}
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              <Typography sx={{ mb: 2 }}>
                <b>Gallery</b>
              </Typography>
              <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                {event_.gallery.map((item) => (
                  <img
                    style={{ width: "150px", marginRight: 16 }}
                    src={`${constants.apiUrl}/documents/${item}`}
                    alt="event_"
                  />
                ))}
              </Stack>
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />
      <Grow>
        <LinearProgress />
      </Grow>
      <DialogActions>
        {false && (
          <Button disabled={isLoading} onClick={() => {}} color="error">
            Remove Listing
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
