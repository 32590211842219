import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiShoppingBag, HiPhone, HiMapPin } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetPayoutQuery } from "../../../../api/endpoints/payouts";
import Cart from "./cart";

export default function Booking({ open, setOpen, _id }) {
  const {
    data: booking = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetPayoutQuery(_id);

  let client;
  let venue;

  if (booking) {
    client = booking.client;
    venue = client.venue;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        Booking Details {"("}
        {booking ? booking.bookingNumber : "loading"}
        {")"}
      </DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {booking && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ alignItems: "center", mt: 2 }} direction="row">
                <Typography sx={{}}>Payout Information</Typography>
              </Stack>
              <Cart _id={booking._id} cart={booking.cart} />
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
