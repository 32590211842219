import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiMapPin } from "react-icons/hi2";
import Chip from "@mui/material/Chip";

import { HiCheck } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import { constants } from "../../../../config/constants";
import {
  useGetEquipmentSingleQuery,
  useDeleteEquipmentMutation,
  useEditEquipmentMutation,
} from "../../../../api/endpoints/equipment";

export default function Equipment({ open, setOpen, _id }) {
  const {
    data: equipment = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetEquipmentSingleQuery(_id);

  const [removeEq, { isLoading: isDeleting }] = useDeleteEquipmentMutation();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const deleteEquipment = async () => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this equipment?"
      );
      if (confirmed) {
        await removeEq(_id);
        setOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [editEquipment, { isLoading: isApproving }] =
    useEditEquipmentMutation();

  const loading = isLoading || isFetching || isDeleting || isApproving;

  const updateEquipment = async () => {
    try {
      await editEquipment({
        _id,
        equipment: {
          approved: true,
        },
      });
      setOpen(false);
    } catch (e) {
      alert("Sorry, request failed");
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Listing details</DialogTitle>

      <Grow in={loading}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {equipment && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Typography sx={{ mb: 1, color: "#CECECE" }}>
                  <b>About equipment</b>
                </Typography>
                <Stack>
                  <Typography>
                    <b>{equipment.title}</b>{" "}
                    <Chip
                      size="small"
                      color="info"
                      sx={{ alignSelf: "start" }}
                      label={equipment.category.title}
                    />
                  </Typography>
                  <Typography>
                    <b>Description:</b> {equipment.description}
                  </Typography>
                  <Typography>
                    <b>Rate:</b> ${numberWithCommas(equipment.rate.toFixed(2))}/
                    {equipment.rentalMode}
                  </Typography>
                  <Stack sx={{ mt: 0 }} direction="row">
                    <HiMapPin style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>
                        <b>{equipment.city}</b>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              <Typography sx={{ mb: 2 }}>
                <b>Gallery</b>
              </Typography>
              <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                {equipment.gallery.map((item) => (
                  <img
                    style={{ width: "150px", marginRight: 16 }}
                    src={`${constants.apiUrl}/documents/${item}`}
                    alt="equipment"
                  />
                ))}
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Listed By</b>
              </Typography>

              {equipment.listedBy && (
                <>
                  {equipment.listedBy.entertainer.representative && (
                    <Stack sx={{ mt: 1 }}>
                      <Stack>
                        <Typography>
                          <b>{equipment.listedBy.entertainer.businessName}</b>
                        </Typography>
                      </Stack>
                      <Chip
                        size="small"
                        color="info"
                        sx={{ alignSelf: "start" }}
                        label="business account"
                      />
                      <Typography>
                        Representative:{" "}
                        {
                          equipment.listedBy.entertainer.representative
                            .firstName
                        }{" "}
                        {equipment.listedBy.entertainer.representative.lastName}
                      </Typography>
                    </Stack>
                  )}
                  {!equipment.listedBy.entertainer.representative && (
                    <Stack sx={{ mt: 1 }}>
                      <Stack
                        sx={{
                          alignItems: "center",
                        }}
                        direction="row"
                      >
                        <Typography>
                          <b>
                            {equipment.listedBy.entertainer.firstName}{" "}
                            {equipment.listedBy.entertainer.lastName}
                          </b>
                        </Typography>
                      </Stack>
                      <Chip
                        size="small"
                        color="info"
                        sx={{ alignSelf: "start" }}
                        label="independent provider"
                      />
                    </Stack>
                  )}
                  <Stack sx={{ mt: 2 }} direction="row">
                    <HiPhone style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>{equipment.listedBy.phone}</Typography>
                    </Stack>
                  </Stack>

                  <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
                </>
              )}
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />
      <Grow>
        <LinearProgress />
      </Grow>
      <DialogActions>
        {!equipment?.approved && !loading && (
          <Button
            onClick={updateEquipment}
            startIcon={<HiCheck />}
            color="success"
          >
            Approve
          </Button>
        )}

        <Button
          disabled={loading}
          onClick={() => {
            deleteEquipment();
          }}
          color="error"
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
