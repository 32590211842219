import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetServicesQuery } from "../../api/endpoints/service";
import { useSelector } from "react-redux";
import Row from "./table/row";

import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Services() {
  const [type, setType] = React.useState("unapproved");

  const { rowsPerPage, page } = useSelector(
    (state) => state.pagination
  ).service;

  const {
    data: services = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetServicesQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    approved: type === "approved" ? true : false,
  });

  const checked = true;

  return (
    <Stack
      sx={{
        pl: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        mt: 2,
        pr: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        height: "100vh",
        overflow: "scroll",
        pb: 16,
      }}
    >
      <Stack
        direction={{
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
        }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        >
          <h2 style={{ margin: 0 }}>
            <span style={{ color: "#6F91C8" }}>Service</span> Listings
          </h2>
        </Stack>
        <RadioGroup
          defaultValue={type}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
            refetch();
          }}
        >
          <Stack sx={{ alignItems: "center" }} direction="row">
            <Radio color="info" value="unapproved" />
            <Typography variant="caption">Unapproved</Typography>
            <Radio color="info" sx={{ ml: 2 }} value="approved" />
            <Typography variant="caption">Approved</Typography>
            <IconButton
              disabled={isFetching || isLoading}
              sx={{ ml: 2 }}
              onClick={refetch}
            >
              <IoMdRefresh />
            </IconButton>
          </Stack>
        </RadioGroup>
        <TablePaginationComponent
          filters={{ approved: type === "approved" ? true : false }}
          loading={isLoading || isFetching}
          data_size={services.length}
          refetch={refetch}
          resource="service"
        />
      </Stack>
      <Stack>
        <TableHeader loading={isFetching || isLoading} />
        <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
          {services.map((service, index) => {
            return (
              <Row
                service={service}
                index={index}
                length={services.length}
                key={service._id}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
