import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetBookingsQuery } from "../../api/endpoints/booking";
import { useSelector } from "react-redux";
import Row from "./table/row";
import Booking from "./table/modal/view";
import { FormControlLabel, IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Bookings() {
  const [type, setType] = React.useState("new");

  const { rowsPerPage, page } = useSelector(
    (state) => state.pagination
  ).booking;

  const {
    data: bookings = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetBookingsQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    type: type,
  });

  const checked = true;

  const [open, setOpen] = React.useState(false);

  const [bookingId, setBookingId] = React.useState(null);

  return (
    <Stack
      sx={{
        ml: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        mt: 2,
        mr: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        flex: 1,
      }}
    >
      <Stack
        direction={{
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
        }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
          py: {
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
          },
        }}
      >
        <Stack
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
            },
          }}
        >
          <h2 style={{ margin: 0 }}>
            <span style={{ color: "#6F91C8" }}>New</span> Bookings
          </h2>
        </Stack>
        <RadioGroup
          defaultValue={type}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
            refetch();
          }}
        >
          <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Unpaid</Typography>}
              control={<Radio color="info" value="unpaid" />}
            />

            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Paid</Typography>}
              control={<Radio color="info" value="new" />}
            />

            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Complete</Typography>}
              control={<Radio color="info" sx={{ ml: 2 }} value="complete" />}
            />

            <IconButton disabled={isFetching || isLoading} onClick={refetch}>
              <IoMdRefresh />
            </IconButton>
          </Stack>
        </RadioGroup>
        <TablePaginationComponent
          filters={{ type: type }}
          loading={isLoading || isFetching}
          data_size={bookings.length}
          refetch={refetch}
          resource="booking"
        />
      </Stack>
      <Stack>
        <TableHeader type={type} loading={isFetching || isLoading} />
        <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
          {bookings
            .filter((x) => x.client)
            .map((booking, index) => {
              return (
                <Row
                  type={type}
                  setOpen={setOpen}
                  setBookingId={setBookingId}
                  booking={booking}
                  index={index}
                  length={bookings.length}
                  key={booking._id}
                />
              );
            })}
        </Stack>
      </Stack>

      {open && <Booking _id={bookingId} open={open} setOpen={setOpen} />}
    </Stack>
  );
}
