import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "./modal/view";
import Chip from "@mui/material/Chip";
import { ButtonBase } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({ event_, index, length }) {
  const checked = true;

  const [open, setOpen] = React.useState(false);

  const {
    title,
    category,
    listedBy,
    date: dt,
    description,
    promoterEvent = false,
    tickets,
  } = event_;

  const date = new Date(dt);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <ButtonBase
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            mt: 1,
          }}
          key={event_._id}
        >
          <Stack
            sx={{
              borderRadius: 0,
              p: 2,
              background: "#1B1B1B",
              borderWidth: 0,
              borderLeftWidth: 1,
              borderStyle: "solid",
              alignItems: "center",
              flex: 1,
              textAlign: "left",
            }}
            direction="row"
          >
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>{title}</Typography>
              <Typography variant="caption">
                {index + 1} of {length}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Typography>{description}</Typography>
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                {listedBy?.venue?.name ||
                  listedBy?.eventPromoter?.businessName ||
                  `${listedBy?.eventPromoter?.firstName || " - "} ${
                    listedBy?.eventPromoter?.lastName || ""
                  }`}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Typography sx={{ color: tickets ? "#00DE36" : "inherit" }}>
                {tickets ? "Yes" : "No"}
              </Typography>
            </Stack>

            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                {date.getDate()} {months[date.getMonth()]} {date.getFullYear()}
              </Typography>
            </Stack>
          </Stack>
        </ButtonBase>
      </Fade>
      {open && <Modal open={open} _id={event_._id} setOpen={setOpen} />}
    </>
  );
}
