import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetEventsQuery } from "../../api/endpoints/event";
import { useSelector } from "react-redux";
import Row from "./table/row";

import { FormControlLabel, IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Events() {
  const [type, setType] = React.useState("upcoming");

  const { rowsPerPage, page } = useSelector((state) => state.pagination)[
    "event_"
  ];

  const {
    data: events = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetEventsQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    type,
  });

  return (
    <Stack
      sx={{
        ml: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        mt: 2,
        mr: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
        },
        flex: 1,
      }}
    >
      <Stack
        direction={{
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
        }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
            },
          }}
        >
          <h2 style={{ margin: 0 }}>Events</h2>
        </Stack>
        <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
          <RadioGroup
            defaultValue={type}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              // refetch();
            }}
          >
            <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
              <FormControlLabel
                label={<Typography variant="caption">Upcoming</Typography>}
                control={<Radio color="info" value="upcoming" />}
              />

              <FormControlLabel
                label={<Typography variant="caption">Complete</Typography>}
                control={<Radio color="info" sx={{ ml: 2 }} value="complete" />}
              />
            </Stack>
          </RadioGroup>

          <IconButton disabled={isFetching || isLoading} onClick={refetch}>
            <IoMdRefresh />
          </IconButton>
        </Stack>

        <TablePaginationComponent
          loading={isLoading || isFetching}
          data_size={events.length}
          refetch={refetch}
          resource="event_"
        />
      </Stack>
      <Stack>
        <TableHeader loading={isFetching || isLoading} />
        <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
          {events.map((event_, index) => {
            return (
              <Row
                event_={event_}
                index={index}
                length={events.length}
                key={event_._id}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
