import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import Modal from "./table/modal/view";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import {
  useGetSaleReportQuery,
  useGetSalesQuery,
} from "../../api/endpoints/sales";
import { useSelector } from "react-redux";
import Row from "./table/row";

import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SalesReport() {
  const [type, setType] = React.useState("upcoming");

  const { rowsPerPage, page } = useSelector((state) => state.pagination)[
    "event_"
  ];

  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState(null);

  const {
    data: reports = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetSalesQuery();

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>Sales Report</h2>
          {false && (
            <RadioGroup
              defaultValue={type}
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                // refetch();
              }}
            >
              <Stack sx={{ alignItems: "center" }} direction="row">
                <Radio color="info" value="upcoming" />
                <Typography variant="caption">Upcoming</Typography>
                <Radio color="info" sx={{ ml: 2 }} value="complete" />
                <Typography variant="caption">Complete</Typography>

                <IconButton
                  disabled={isFetching || isLoading}
                  sx={{ ml: 2 }}
                  onClick={refetch}
                >
                  <IoMdRefresh />
                </IconButton>
              </Stack>
            </RadioGroup>
          )}
          <IconButton
            disabled={isFetching || isLoading}
            sx={{ ml: 2 }}
            onClick={refetch}
          >
            <IoMdRefresh />
          </IconButton>

          {false && (
            <TablePaginationComponent
              loading={isLoading || isFetching}
              data_size={reports.length}
              refetch={refetch}
              resource="event_"
            />
          )}
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {reports.map((r, index) => {
              return (
                <Row
                  setOpen={setOpen}
                  setReport={setReport}
                  report={r}
                  index={index}
                  length={reports.length}
                  key={r._id}
                />
              );
            })}
          </Stack>
        </Stack>
        {open && <Modal open={open} report={report} setOpen={setOpen} />}
      </Box>
    </Grow>
  );
}
