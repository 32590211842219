/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { IoMdCheckmark } from "react-icons/io";
import Typography from "@mui/material/Typography";

import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";

import LinearProgress from "@mui/material/LinearProgress";

export default function TableHeader({ loading }) {
  return (
    <Stack
      sx={{
        background: "rgba(255, 255, 255, 0.12)",
        borderRadius: 2,
        mt: 2,
      }}
    >
      <Grow in={loading}>
        <LinearProgress sx={{ borderRadius: 2 }} />
      </Grow>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          borderRadius: 2,
          p: 2,
          flex: 1,
        }}
      >
        <Typography sx={{ flex: 1, pr: 2 }}>
          <b>Event</b>
        </Typography>

        <Typography sx={{ flex: 1, pr: 2 }}>
          <b>Promoter</b>
        </Typography>

        <Typography sx={{ flex: 1, pr: 2 }}>
          <b>Sales</b>
        </Typography>
        <Typography
          sx={{
            flex: 1,
            pr: 2,
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        >
          <b>Commission</b>
        </Typography>
        <Typography
          sx={{
            flex: 1,
            pr: 2,
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        >
          <b>Net Payout</b>
        </Typography>
      </Stack>
    </Stack>
  );
}
