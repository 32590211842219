import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Booking from "./modal/view";
import { ButtonBase } from "@mui/material";

export default function Row({ user, index, length }) {
  const checked = true;

  const {
    phone,
    email,
    _id,
    corporate,
    venue,
    eventPromoter,
    firstName,
    lastName,
    isParentAccount,
    organizationName,
  } = user;

  const [open, setOpen] = React.useState(false);

  let accountName = React.useMemo(() => {
    let accountName = "";
    if (firstName) {
      accountName = `${firstName} ${lastName}`;
    }

    if (organizationName) {
      accountName = organizationName;
    }

    return accountName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let accountType = React.useMemo(() => {
    let accountType = "";
    if (firstName) {
      accountType = "Personal";
    }

    if (corporate) {
      accountType = "Corporate";
    }

    if (isParentAccount) {
      accountType = "Master Account";
    }

    return accountType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <Stack
          sx={{
            borderRadius: 0,
            p: 2,
            mt: 1,
            background: "#1B1B1B",
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: "solid",
            alignItems: "center",
            flex: 1,
          }}
          direction="row"
        >
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography
              onClick={() => {
                //setOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  // textDecorationLine: "underline",
                },
              }}
            >
              {accountName}
            </Typography>

            <Typography variant="caption">
              {index + 1} of {length}
            </Typography>
          </Stack>
          <Typography sx={{ flex: 1, pr: 2 }}>
            <b>{accountType}</b>
          </Typography>

          <Typography sx={{ flex: 1, pr: 2 }}>{phone || " - "}</Typography>
          <Typography
            sx={{
              flex: 1,
              pr: 2,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            {email}
          </Typography>
        </Stack>
      </Fade>
      {open && (
        <Booking open={open} _id={eventPromoter._id} setOpen={setOpen} />
      )}
    </>
  );
}
