import Stack from "@mui/material/Stack";
import { useState } from "react";
import FormContainer from "./components/form";
import { useNavigate } from "react-router-dom";
import { constants } from "../../config/constants";
import { setUser } from "../../redux/slices/user";
import { useDispatch } from "react-redux";

const Signin = () => {
  const [profile, setProfile] = useState({
    password: "",
    email: "",
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  // window.localStorage.removeItem("user");
  //window.localStorage.removeItem("token");

  //dispatch(setUser({ loaded: true, user: null }));

  const [error, setError] = useState(null);

  const handleLogin = () => {
    setError(null);
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(profile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setTimeout(() => {
      fetch(constants.apiUrl + "/users/login", requestOptions)
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Incorrect email or password");
        })
        .then(async (result) => {
          if (result.error) {
            setError(result.error);
          } else {
            if (result.user) {
              window.localStorage.setItem("user", JSON.stringify(result.user));
              window.localStorage.setItem(
                "token",
                JSON.stringify(result.token)
              );
              dispatch(setUser({ loaded: true, user: result.user }));
              navigate("/");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    }, 1500);
  };

  return (
    <Stack>
      <Stack sx={{ height: "100vh", width: "100vw" }} direction="row">
        <FormContainer
          error={error}
          handleLogin={handleLogin}
          loading={loading}
          profile={profile}
          setProfile={setProfile}
        />
      </Stack>
    </Stack>
  );
};
export default Signin;
