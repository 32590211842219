import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/sales/reporting`,
          method: "GET",
        };
      },
    }),
    getSaleReport: builder.query({
      query: (eventID) => {
        return { url: `/sales/reporting?eventId=${eventID}`, method: "GET" };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetSalesQuery, useGetSaleReportQuery } = extendedApi;
