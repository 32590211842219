import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import {
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
} from "@mui/material";
import { constants } from "../../config/constants";

export default function Settings() {
  const checked = true;

  const [values, setValues] = React.useState({
    exchangeRate: null,
    kmRate: null,
    commission: null,
    ticketingCommission: null,
  });

  const getConfig = () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/resources/config`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setValues(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getConfig();
  }, []);

  const [loading, setLoading] = React.useState(false);

  const saveChanges = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ...values,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/resources/config`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        alert("Changes saved!");
      })
      .catch((error) => {
        setLoading(false);
        alert("Error saving changes, please try again");
      });
  };

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
          overflow: "scroll",
          pb: 16,
        }}
      >
        <Stack
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            Edit <span style={{ color: "#6F91C8" }}>Platform Rates</span>
          </h2>
        </Stack>
        <Grow in={loading}>
          <LinearProgress sx={{ borderRadius: 2 }} />
        </Grow>
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Commission (Bookings)</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.commission}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    commission: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit commission"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Commission (Tickets)</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.ticketingCommission}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    ticketingCommission: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit commission"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Rate per KM</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.kmRate}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    kmRate: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit rate per km"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Exchange Rate</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.exchangeRate}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    exchangeRate: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit exhange rate"
              />
            </Stack>
          </Stack>
        </Stack>
        <Button
          disabled={
            !values.kmRate ||
            !values.commission ||
            !values.exchangeRate ||
            loading
          }
          onClick={saveChanges}
          sx={{
            textTransform: "none",
            mt: 4,
            height: 50,
          }}
          variant="contained"
        >
          Save Changes
        </Button>
      </Box>
    </Grow>
  );
}
