import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiMapPin, HiCheckBadge } from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import { constants } from "../../../../config/constants";
import {
  useGetEntertainerQuery,
  useEditEntertainerMutation,
  useRemoveEntertainerMutation,
} from "../../../../api/endpoints/entertainer";

export default function Profile({ open, setOpen, _id }) {
  const {
    data: profile = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetEntertainerQuery(_id);

  const [editEntertainer, { isLoading: isUpdating }] =
    useEditEntertainerMutation();
  const updateEntertainer = async (verificationStatus) => {
    try {
      let result = await editEntertainer({
        _id: profile._id,
        user: {
          updateType: "verification",
          verificationStatus,
        },
      });
      if (result.error) {
        throw new Error("Request failed");
      }
      setOpen(false);
    } catch (e) {
      alert("Sorry, request failed");
    }
  };

  const [deleteUser, { isLoading: isDeleting }] =
    useRemoveEntertainerMutation();

  const removeUser = async () => {
    // Confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!isConfirmed) {
      return; // Exit the function if the user clicks 'Cancel'
    }

    try {
      let result = await deleteUser(profile._id);
      if (result.error) {
        throw new Error("Request failed");
      }
      setOpen(false);
    } catch (e) {
      alert("Sorry, request failed");
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Service Provider Profile</DialogTitle>

      <Grow in={isLoading || isFetching || isDeleting}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {profile && (
          <Grow in={!isLoading}>
            <div>
              {profile.entertainer.representative && (
                <Stack sx={{ mt: 1 }}>
                  <Stack>
                    <Typography>
                      <b>{profile.entertainer.businessName}</b>{" "}
                      {profile.entertainer.verificationStatus ===
                        "approved" && <HiCheckBadge />}{" "}
                    </Typography>
                  </Stack>
                  <Chip
                    size="small"
                    color="info"
                    sx={{ alignSelf: "start" }}
                    label="business account"
                  />
                  <Typography>
                    Representative:{" "}
                    {profile.entertainer.representative.firstName}{" "}
                    {profile.entertainer.representative.lastName}
                  </Typography>
                </Stack>
              )}
              {!profile.entertainer.representative && (
                <Stack sx={{ mt: 1 }}>
                  <Stack
                    sx={{
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <Typography>
                      <b>
                        {profile.entertainer.firstName}{" "}
                        {profile.entertainer.lastName}
                      </b>
                      {profile.entertainer.verificationStatus ===
                        "approved" && <HiCheckBadge />}{" "}
                    </Typography>
                  </Stack>
                  <Chip
                    size="small"
                    color="info"
                    sx={{ alignSelf: "start" }}
                    label="independent provider"
                  />
                </Stack>
              )}
              <Stack sx={{ mt: 2 }} direction="row">
                <HiPhone style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>{profile.phone}</Typography>
                </Stack>
              </Stack>

              <Stack sx={{ mt: 0 }} direction="row">
                <HiMapPin style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>
                    <b>{profile.entertainer.city}</b>
                  </Typography>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              <Typography sx={{}}>
                <b>Proof of identity</b>
              </Typography>
              <Typography sx={{ mb: 2 }}>
                ID/Registration Number:{" "}
                {profile.entertainer.identification.registrationNumber ||
                  profile.entertainer.identification.identityNumber}
              </Typography>
              <img
                style={{ width: "100%" }}
                src={`${constants.apiUrl}/documents/${profile.entertainer.identification.document}`}
                alt="Proof of Identity"
              />
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />
      <Grow in={isUpdating}>
        <LinearProgress />
      </Grow>
      <DialogActions>
        <Button
          onClick={() => {
            updateEntertainer("approved");
          }}
          disabled={
            isLoading ||
            isUpdating ||
            profile.entertainer.verificationStatus === "approved"
          }
          startIcon={<HiCheckBadge />}
          color="success"
        >
          Approve
        </Button>
        <Button
          disabled={
            isLoading ||
            isUpdating ||
            profile.entertainer.verificationStatus === "approved"
          }
          onClick={() => {
            updateEntertainer("declined");
          }}
          color="error"
        >
          Decline
        </Button>
        {profile?.entertainer.verificationStatus === "approved" && (
          <Button
            disabled={isLoading || isUpdating || isDeleting}
            onClick={() => {
              removeUser();
            }}
            color="error"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
