import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Grow from "@mui/material/Grow";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import { useAddNewScheduleMutation } from "../../../api/endpoints/charges";
import { NumericFormatCustom } from "../../../layouts/common/inputs/view";

export default function NewSchedule({ open, setOpen }) {
  const [charge, setCharge] = useState({
    title: "",
    description: "",
    onceOffRate: "",
    recurringRate: "",
  });

  const [addSchedule, { isLoading }] = useAddNewScheduleMutation();

  const saveSchedule = async () => {
    let result = await addSchedule(charge);
    if (result.error) {
      return alert("Sorry, request failed");
    }
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>New Charge Group</DialogTitle>

      <Grow in={isLoading}>
        <LinearProgress />
      </Grow>

      <DialogContent sx={{ height: "60vh" }}>
        <TextField
          onChange={(e) => {
            setCharge((charge) => ({ ...charge, title: e.target.value }));
          }}
          value={charge.title}
          autoComplete="password"
          fullWidth
          label="Title"
        />

        <TextField
          onChange={(e) => {
            setCharge((charge) => ({ ...charge, description: e.target.value }));
          }}
          value={charge.description}
          autoComplete="password"
          fullWidth
          sx={{ mt: 2.5 }}
          label="Description"
        />

        <TextField
          onChange={(e) => {
            setCharge((charge) => ({ ...charge, onceOffRate: e.target.value }));
          }}
          value={charge.onceOffRate}
          fullWidth
          autoComplete="password"
          required
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          sx={{ mt: 2.5 }}
          label="Once-off rate"
        />
        <TextField
          fullWidth
          autoComplete="password"
          value={charge.recurringRate}
          onChange={(e) => {
            setCharge((charge) => ({
              ...charge,
              recurringRate: e.target.value,
            }));
          }}
          required
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          sx={{ mt: 2.5 }}
          label="Multi-date rate"
        />
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button
          disabled={
            charge.title.trim() === "" ||
            charge.onceOffRate.trim() === "" ||
            isLoading
          }
          onClick={saveSchedule}
        >
          Save
        </Button>
        <Button
          disabled={isLoading}
          color="warning"
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
