import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiMapPin, HiCheckBadge, HiCheck } from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { constants } from "../../../../config/constants";
import Link from "@mui/material/Link";
import { useGetSchedulesQuery } from "../../../../api/endpoints/charges";
import {
  useGetServiceQuery,
  useEditServiceMutation,
  useEditServiceUpdateMutation,
} from "../../../../api/endpoints/service";
import { SiFacebook, SiInstagram, SiBiolink } from "react-icons/si";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Service({ open, setOpen, _id }) {
  const {
    data: service = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetServiceQuery(_id);

  console.log(service);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const { data: schedules = [], isLoading: loadingSchedules } =
    useGetSchedulesQuery({ limit: 0, skip: 0 });

  const [editService, { isLoading: isApproving }] = useEditServiceMutation();

  const [editServiceUpdate, { isLoading: isUpdating }] =
    useEditServiceUpdateMutation();

  const [serviceSchedule, setSchedule] = React.useState(null);
  const updateEntertainer = async () => {
    try {
      let result;
      if (service.approved === true) {
        result = await editServiceUpdate({
          _id: service._id,
          service: {
            costSchedule: serviceSchedule._id,
          },
        });
      } else {
        result = await editService({
          _id: service._id,
          service: {
            approved: true,
            costSchedule: serviceSchedule._id,
          },
        });
      }
      if (result.error) {
        throw new Error("Request failed");
      }
      setOpen(false);
    } catch (e) {
      alert("Sorry, request failed");
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Listing details</DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {service && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Typography sx={{ mb: 1, color: "#CECECE" }}>
                  <b>About service</b>
                </Typography>
                <Stack>
                  <Typography>
                    <b>{service.title}</b>{" "}
                    <Chip
                      size="small"
                      color="info"
                      sx={{ alignSelf: "start" }}
                      label={service.category.title}
                    />
                  </Typography>
                  <Typography variant="caption">
                    <b>Description:</b> {service.description}
                  </Typography>
                  <Stack sx={{ mt: 0 }} direction="row">
                    <HiMapPin style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>
                        <b>{service.city}</b>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              <Typography sx={{ mb: 2 }}>
                <b>Gallery</b>
              </Typography>
              <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                {service.gallery.map((item) => (
                  <img
                    style={{ width: "150px", marginRight: 16 }}
                    src={`${constants.apiUrl}/documents/${item}`}
                    alt="service"
                  />
                ))}
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Listed By</b>
              </Typography>

              {service.listedBy && (
                <>
                  {service.listedBy.entertainer.representative && (
                    <Stack sx={{ mt: 1 }}>
                      <Stack>
                        <Typography>
                          <b>{service.listedBy.entertainer.businessName}</b>
                        </Typography>
                      </Stack>
                      <Chip
                        size="small"
                        color="info"
                        sx={{ alignSelf: "start" }}
                        label="business account"
                      />
                      <Typography>
                        Representative:{" "}
                        {service.listedBy.entertainer.representative.firstName}{" "}
                        {service.listedBy.entertainer.representative.lastName}
                      </Typography>
                    </Stack>
                  )}
                  {!service.listedBy.entertainer.representative && (
                    <Stack sx={{ mt: 1 }}>
                      <Stack
                        sx={{
                          alignItems: "center",
                        }}
                        direction="row"
                      >
                        <Typography>
                          <b>
                            {service.listedBy.entertainer.firstName}{" "}
                            {service.listedBy.entertainer.lastName}
                          </b>
                        </Typography>
                      </Stack>
                      <Chip
                        size="small"
                        color="info"
                        sx={{ alignSelf: "start" }}
                        label="independent provider"
                      />
                    </Stack>
                  )}
                  <Stack sx={{ mt: 2 }} direction="row">
                    <HiPhone style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>{service.listedBy.phone}</Typography>
                    </Stack>
                  </Stack>

                  <Stack sx={{ mt: 0 }} direction="row">
                    <HiMapPin style={{ marginTop: 4 }} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography>
                        <b>{service.listedBy.entertainer.city}</b>
                      </Typography>
                    </Stack>
                  </Stack>

                  <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
                  <Typography sx={{}}>
                    <b>Social Links</b>
                  </Typography>
                  {service.listedBy.socialLinks.length === 0 && (
                    <Typography>No social links</Typography>
                  )}
                  <Stack sx={{ mt: 1 }}>
                    {service.listedBy.socialLinks.map((link) => (
                      <Stack sx={{ mb: 1 }} direction="row">
                        <Link target="_blank" href={link.link}>
                          {link.channel === "facebook" && (
                            <>
                              <SiFacebook />{" "}
                            </>
                          )}
                          {link.channel === "instagram" && (
                            <>
                              <SiInstagram />{" "}
                            </>
                          )}
                          {link.channel !== "instagram" &&
                            link.channel !== "facebook" && (
                              <>
                                <SiBiolink />{" "}
                              </>
                            )}
                          {link.channel}
                        </Link>
                      </Stack>
                    ))}
                  </Stack>
                </>
              )}

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Cost Schedule</b>
              </Typography>

              <Autocomplete
                sx={{ mt: 2 }}
                value={
                  serviceSchedule
                    ? serviceSchedule
                    : service.costSchedule
                    ? schedules.find((a) => a._id === service.costSchedule._id)
                    : null
                }
                onChange={(e, newValue) => {
                  setSchedule(newValue);
                }}
                getOptionLabel={(option) => option.title}
                options={schedules}
                loading={isLoading}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.title}:{" "}
                    {"OOR-$" +
                      numberWithCommas((option.onceOffRate || 0).toFixed(0))}
                    ,
                    {"RR -$" +
                      numberWithCommas((option.recurringRate || 0).toFixed(0))}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select charge group"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSchedules ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />
      <Grow in={isApproving || isUpdating}>
        <LinearProgress />
      </Grow>
      <DialogActions>
        {service && (
          <Button
            disabled={
              !serviceSchedule || isLoading || isApproving || isUpdating
            }
            onClick={() => {
              updateEntertainer();
            }}
            startIcon={
              service.approved === true ? <HiCheck /> : <HiCheckBadge />
            }
            color="success"
          >
            {service.approved ? "Update" : "Approve"}
          </Button>
        )}
        {false && (
          <Button
            disabled={isLoading || isApproving}
            onClick={() => {
              updateEntertainer("declined");
            }}
            color="error"
          >
            Remove Listing
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
