import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { HiClock, HiCheckCircle } from "react-icons/hi2";
import { useMarkSettlementPaidMutation } from "../../../../api/endpoints/payouts";
import Profile from "./profile";
import { LinearProgress } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Cart({ _id, cart }) {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let [editPayout, { isLoading = false }] = useMarkSettlementPaidMutation();

  const updatePayout = async (date, cart_item_id) => {
    let dates = [];
    cart.forEach((item) => {
      dates = [...dates, ...item.dates];
    });

    let paidOutDates = [];

    cart.forEach((item) => {
      paidOutDates = [...paidOutDates, ...item.paidOutDates];
    });
    paidOutDates.push(date);
    let result = await editPayout({
      _id,
      cart_item_id,
      payout: {
        date: date,
        paidOut: dates.length === paidOutDates.length,
      },
    });
    if (!result.error) {
    }
  };

  return (
    <Stack sx={{ mt: 2 }}>
      {cart.map((item) => {
        let service = item.service;
        let equipment = item.equipment;

        return (
          <Stack sx={{ background: "#353535", p: 2, borderRadius: 2 }}>
            <Stack>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                direction="row"
              >
                <Stack
                  sx={{
                    alignItems: "center",
                  }}
                  direction="row"
                >
                  <Typography>
                    <b>{service ? service.title : equipment.title}</b>
                  </Typography>
                  <Chip
                    size="small"
                    color="info"
                    variant="outlined"
                    sx={{ ml: 0.5 }}
                    label={
                      service
                        ? service.category.title
                        : equipment.category.title
                    }
                  />
                </Stack>
              </Stack>
              <Stack sx={{ mt: 1 }}>
                {item.dates.map((e, index) => {
                  let date = new Date(e);
                  let total = 0;
                  if (item.days) {
                    total = item.rate * item.days;
                  } else {
                    total = item.rate * item.hours;
                  }
                  total += total * (item.commission / 100);
                  total += item.transport;

                  let currDate = new Date();

                  let newBooking = currDate <= date;

                  return (
                    <Stack sx={{ mb: 1 }}>
                      <Stack sx={{ alignItems: "center" }} direction="row">
                        {false && (
                          <>
                            {newBooking ? (
                              <HiClock />
                            ) : (
                              <HiCheckCircle color="#00AA33" />
                            )}
                          </>
                        )}
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                          {date.getDate()} {months[date.getMonth()]}{" "}
                          {date.getFullYear()} -{" "}
                          {item.days
                            ? item.days + " day(s)"
                            : item.hours + " hour(s)"}{" "}
                        </Typography>
                      </Stack>
                      <Typography>
                        - Service charge: $
                        <b>
                          {numberWithCommas(
                            (
                              item.rate * (item.days ? item.days : item.hours)
                            ).toFixed(2)
                          )}
                        </b>
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Chip
                          variant="filled"
                          label={
                            (item.paidOutDates || []).includes(e)
                              ? "Payout Complete"
                              : "Pending Payout"
                          }
                          color={
                            (item.paidOutDates || []).includes(e)
                              ? "info"
                              : "warning"
                          }
                        ></Chip>

                        <Button
                          disabled={
                            isLoading || (item.paidOutDates || []).includes(e)
                          }
                          onClick={() => {
                            updatePayout(e, item._id);
                          }}
                          sx={{ borderRadius: 32 }}
                        >
                          Mark As Paid
                        </Button>
                      </Stack>

                      {false && (
                        <Typography variant="subtitle2">
                          - Transport: $
                          {numberWithCommas(item.transport.toFixed(2))}
                        </Typography>
                      )}
                      {item.dates.length !== index + 1 && !isLoading && (
                        <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
                      )}
                      {isLoading && <LinearProgress sx={{ mt: 2, mb: 2 }} />}
                    </Stack>
                  );
                })}
              </Stack>

              <Profile
                profile={service ? service.listedBy : equipment.listedBy}
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
