import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import TableHeader from "./table/header";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetEquipmentListQuery } from "../../api/endpoints/equipment";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Row from "./table/row";

import { FormControlLabel, IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Equipment() {
  const [type, setType] = React.useState("unapproved");

  const { rowsPerPage, page } = useSelector(
    (state) => state.pagination
  ).equipment;

  const {
    data: equipmentList = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetEquipmentListQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    approved: type === "approved" ? true : false,
  });

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
          overflow: "scroll",
          pb: 16,
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            <h2 style={{ margin: 0 }}>
              <span style={{ color: "#6F91C8" }}>Equipment</span> Listings
            </h2>
          </Stack>

          <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
            <RadioGroup
              defaultValue={type}
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                refetch();
              }}
            >
              <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
                <FormControlLabel
                  control={<Radio color="info" value="unapproved" />}
                  label={<Typography variant="caption">Unapproved</Typography>}
                />
                <FormControlLabel
                  control={<Radio color="info" value="approved" />}
                  label={<Typography variant="caption">Approved</Typography>}
                />
              </Stack>
            </RadioGroup>

            <IconButton disabled={isFetching || isLoading} onClick={refetch}>
              <IoMdRefresh />
            </IconButton>
          </Stack>

          <TablePaginationComponent
            filters={{ approved: type === "approved" ? true : false }}
            loading={isLoading || isFetching}
            data_size={equipmentList.length}
            refetch={refetch}
            resource="equipment"
          />
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {equipmentList
              .filter((x) => x.listedBy != null)
              .map((equipment, index) => {
                return (
                  <Row
                    equipment={equipment}
                    index={index}
                    length={equipmentList.length}
                    key={equipment._id}
                  />
                );
              })}
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
}
