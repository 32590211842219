import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, type: "new" };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBookings: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/bookings/?limit=${options.limit}&skip=${options.skip}&type=${options.type}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Booking", id: _id })),
        { type: "Booking", id: "LIST" },
      ],
    }),
    getBooking: builder.query({
      query: (bookingID) => {
        return { url: `/bookings/single/${bookingID}`, method: "GET" };
      },
    }),
    getBookingResources: builder.query({
      query: () => {
        return { url: `/resources/booking`, method: "GET" };
      },
    }),
    addNewBooking: builder.mutation({
      query: (booking) => ({
        url: "/bookings",
        method: "POST",
        body: booking,
      }),
      invalidatesTags: [{ type: "Booking", id: "LIST" }],
    }),
    editBooking: builder.mutation({
      query: (data) => ({
        url: `/bookings/${data._id}`,
        method: "PATCH",
        body: data.booking,
      }),
      async onQueryStarted({ _id, booking }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedBooking } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getBookings", params, (draft) => {
              const booking = draft.find((e) => e._id === updatedBooking._id);
              if (booking) {
                Object.keys(updatedBooking).forEach((key) => {
                  booking[key] = updatedBooking[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getBooking", _id, (draft) => {
              Object.assign(draft, updatedBooking);
            })
          );
        } catch {}
      },
    }),
    processPop: builder.mutation({
      query: (data) => ({
        url: `/bookings/${data._id}/process-pop`,
        method: "PATCH",
        body: data.booking,
      }),
      async onQueryStarted({ _id, booking }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedBooking } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getBookings", params, (draft) => {
              const booking = draft.find((e) => e._id === updatedBooking._id);
              if (booking) {
                Object.keys(updatedBooking).forEach((key) => {
                  booking[key] = updatedBooking[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getBooking", _id, (draft) => {
              Object.assign(draft, updatedBooking);
            })
          );
        } catch {}
      },
      invalidatesTags: [{ type: "Booking", id: "LIST" }],
    }),
    deleteBooking: builder.mutation({
      query: (_id) => ({
        url: `/bookings/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Booking", id: "LIST" }],
    }),
    deleteBookings: builder.mutation({
      query: (data) => ({
        url: `/bookings/many`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [{ type: "Booking", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBookingsQuery,
  useGetBookingQuery,
  useGetBookingResourcesQuery,
  useAddNewBookingMutation,
  useEditBookingMutation,
  useDeleteBookingMutation,
  useDeleteBookingsMutation,
  useProcessPopMutation,
} = extendedApi;
