import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, type: "pending" };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayouts: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/payouts/?limit=${options.limit}&skip=${options.skip}&type=${options.type}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Payout", id: _id })),
        { type: "Payout", id: "LIST" },
      ],
    }),
    getPayout: builder.query({
      query: (payoutID) => {
        return { url: `/payouts/single/${payoutID}`, method: "GET" };
      },
    }),
    markSettlementPaid: builder.mutation({
      query: (data) => ({
        url: `/payouts/mark_paid/${data._id}/${data.cart_item_id}`,
        method: "PATCH",
        body: data.payout,
      }),
      async onQueryStarted({ _id, payout }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPayout } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getPayouts", params, (draft) => {
              const payout = draft.find((e) => e._id === updatedPayout._id);
              if (payout) {
                Object.keys(updatedPayout).forEach((key) => {
                  payout[key] = updatedPayout[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getPayout", _id, (draft) => {
              Object.assign(draft, updatedPayout);
            })
          );
        } catch {}
      },
      invalidatesTags: [{ type: "Payout", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPayoutsQuery,
  useGetPayoutQuery,
  useMarkSettlementPaidMutation,
} = extendedApi;
