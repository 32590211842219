import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: {
    booking: {
      rowsPerPage: 50,
      page: 0,
    },
    charge: {
      rowsPerPage: 50,
      page: 0,
    },
    user: {
      rowsPerPage: 100,
      page: 0,
    },
    entertainer: {
      rowsPerPage: 50,
      page: 0,
    },
    venue: {
      rowsPerPage: 50,
      page: 0,
    },
    service: {
      rowsPerPage: 50,
      page: 0,
    },
    event_: {
      rowsPerPage: 50,
      page: 0,
    },
    equipment: {
      rowsPerPage: 50,
      page: 0,
    },
    payout: {
      rowsPerPage: 50,
      page: 0,
    },
  },
  reducers: {
    setPage(state, action) {
      state[action.payload.target].page = action.payload.page;
    },
    setRowsPerPage(state, action) {
      state[action.payload.target].rowsPerPage = action.payload.rowsPerPage;
    },
  },
});

export const { setPage, setRowsPerPage } = paginationSlice.actions;
export default paginationSlice.reducer;
