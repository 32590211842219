import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchedules: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/schedules/?limit=${options.limit}&skip=${options.skip}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        ...result.map(({ _id }) => ({ type: "Schedule", id: _id })),
        { type: "Schedule", id: "LIST" },
      ],
    }),
    getSchedule: builder.query({
      query: (scheduleID) => {
        return { url: `/schedules/single/${scheduleID}`, method: "GET" };
      },
    }),
    getScheduleResources: builder.query({
      query: () => {
        return { url: `/resources/schedule`, method: "GET" };
      },
    }),
    addNewSchedule: builder.mutation({
      query: (schedule) => ({
        url: "/schedules",
        method: "POST",
        body: schedule,
      }),
      invalidatesTags: [{ type: "Schedule", id: "LIST" }],
    }),
    editSchedule: builder.mutation({
      query: (data) => ({
        url: `/schedules/${data._id}`,
        method: "PATCH",
        body: data.schedule,
      }),
      async onQueryStarted({ _id, schedule }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSchedule } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getSchedules", params, (draft) => {
              const schedule = draft.find((e) => e._id === updatedSchedule._id);
              if (schedule) {
                Object.keys(updatedSchedule).forEach((key) => {
                  schedule[key] = updatedSchedule[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getSchedule", _id, (draft) => {
              Object.assign(draft, schedule);
            })
          );
        } catch {}
      },
    }),
    deleteSchedule: builder.mutation({
      query: (_id) => ({
        url: `/schedules/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Schedule", id: "LIST" }],
    }),
    deleteSchedules: builder.mutation({
      query: (data) => ({
        url: `/schedules/many`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [{ type: "Schedule", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSchedulesQuery,
  useGetScheduleQuery,
  useGetScheduleResourcesQuery,
  useAddNewScheduleMutation,
  useEditScheduleMutation,
  useDeleteScheduleMutation,
  useDeleteSchedulesMutation,
} = extendedApi;
