import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetPayoutsQuery } from "../../api/endpoints/payouts";
import { useSelector } from "react-redux";
import Row from "./table/row";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Earnings() {
  const [type, setType] = React.useState("pending");

  const { rowsPerPage, page } = useSelector(
    (state) => state.pagination
  ).payout;

  const {
    data: payouts = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetPayoutsQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    type: type,
  });

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            <span style={{ color: "#6F91C8" }}>Earnings</span> History
          </h2>
          <RadioGroup
            defaultValue={type}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              refetch();
            }}
          >
            <Stack sx={{ alignItems: "center" }} direction="row">
              <Radio color="info" value="pending" />
              <Typography variant="caption">Pending</Typography>
              <Radio color="info" sx={{ ml: 2 }} value="complete" />
              <Typography variant="caption">Complete</Typography>
            </Stack>
          </RadioGroup>
          <TablePaginationComponent
            filters={{ type: type }}
            loading={isLoading || isFetching}
            data_size={payouts.length}
            refetch={refetch}
            resource="payout"
          />
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {payouts.map((booking, index) => {
              return (
                <Row
                  booking={booking}
                  index={index}
                  length={payouts.length}
                  key={booking._id}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
}
