import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "./modal/view";
import Chip from "@mui/material/Chip";
import { ButtonBase } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({ report, index, length, setReport, setOpen }) {
  const checked = true;

  const {
    listedByDetails,

    eventDetails: event,
    totalSales,
    commission,
    netRevenue,
  } = report;

  let listedBy = listedByDetails.eventPromoter;
  if (!listedBy) {
    return null;
  }

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <ButtonBase
          sx={{ mt: 1, textAlign: "left" }}
          onClick={() => {
            setReport(report);
            setOpen(true);
          }}
        >
          <Stack
            key={report._id}
            sx={{
              borderRadius: 0,
              flex: 1,
              p: 2,
              background: "#1B1B1B",
              borderWidth: 0,
              borderLeftWidth: 1,
              borderStyle: "solid",
              alignItems: "center",
            }}
            direction="row"
          >
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecorationLine: "underline",
                  },
                }}
              >
                {event?.title}
              </Typography>
              <Typography variant="caption">
                {index + 1} of {length}
              </Typography>
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                {listedBy?.businessName ||
                  `${listedBy?.firstName} ${listedBy?.lastName}`}
              </Typography>
            </Stack>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>${totalSales.toFixed(2)}</Typography>
              <Typography variant="caption">
                {event.tickets.reduce(
                  (total, ticket) => total + ticket.sold,
                  0
                )}{" "}
                tickets
              </Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            >
              <Typography>${commission.toFixed(2)}</Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            >
              <Typography>${netRevenue.toFixed(2)}</Typography>
            </Stack>
          </Stack>
        </ButtonBase>
      </Fade>
    </>
  );
}
