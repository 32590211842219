import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetSchedulesQuery } from "../../api/endpoints/charges";
import { useSelector } from "react-redux";
import { HiPlusCircle } from "react-icons/hi2";
import Row from "./table/row";
import NewSchedule from "./new-schedule/view";

export default function Charges() {
  const [type, setType] = React.useState("new");

  const { rowsPerPage, page } = useSelector((state) => state.pagination).charge;

  const {
    data: charges = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetSchedulesQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
  });

  console.log(charges);

  const checked = true;

  const [open, setOpen] = React.useState(false);

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
          overflow: "scroll",
          pb: 16,
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            <h2 style={{ margin: 0 }}>
              <span style={{ color: "#6F91C8" }}>Charge</span> Sheet
            </h2>
          </Stack>

          <Stack direction="row" sx={{ alignItems: "center" }}>
            <TablePaginationComponent
              loading={isLoading || isFetching}
              data_size={charges.length}
              refetch={refetch}
              resource="charge"
            />
          </Stack>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            sx={{ ml: 2 }}
          >
            <HiPlusCircle />
          </IconButton>
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {charges.map((schedule, index) => {
              return (
                <Row
                  schedule={schedule}
                  index={index}
                  length={[].length}
                  key={schedule._id}
                />
              );
            })}
          </Stack>
        </Stack>
        <NewSchedule open={open} setOpen={setOpen} />
      </Box>
    </Grow>
  );
}
