import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { constants } from "../../../../config/constants";
import { useProcessPopMutation } from "../../../../api/endpoints/booking";
import "react-medium-image-zoom/dist/styles.css";
import Zoom from "react-medium-image-zoom";
import { Button, Chip, CircularProgress } from "@mui/material";

export default function ProofOfPayment({ booking }) {
  const [editBooking, { isLoading }] = useProcessPopMutation();

  const updateBooking = async (action) => {
    try {
      await editBooking({
        _id: booking._id,
        booking: {
          action,
        },
      });
    } catch (e) {
      alert("Error processing POP", e.message);
    }
  };
  return (
    <Stack sx={{ mt: 2 }}>
      <Stack sx={{ background: "#353535", p: 2, borderRadius: 2 }}>
        <Stack>
          <Typography sx={{ mb: 1 }}>Proof Of Payment</Typography>

          <Zoom>
            <img
              alt="pop"
              style={{ width: "100%", objectFit: "contain", borderRadius: 16 }}
              src={`${constants.apiUrl}/documents/${booking.proofOfPayment}`}
            />
          </Zoom>
          {booking?.proofOfPaymentApproved && (
            <Chip
              color="success"
              sx={{ alignSelf: "flex-start" }}
              label="Approved"
            />
          )}

          {isLoading && <CircularProgress size={16} sx={{ m: 1 }} />}

          {!booking.proofOfPaymentApproved && (
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => {
                  updateBooking("approve");
                }}
                disabled={isLoading}
                color="success"
              >
                Approve
              </Button>
              <Button
                onClick={() => {
                  updateBooking("decline");
                }}
                disabled={isLoading}
                color="error"
              >
                Decline
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
