import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import TablePaginationComponent from "../../layouts/common/pagination/view";
import { useGetBookingsQuery } from "../../api/endpoints/entertainer";
import { useGetEntertainersQuery } from "../../api/endpoints/entertainer";
import { useSelector } from "react-redux";
import Row from "./table/row";
import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Verifications() {
  const [type, setType] = React.useState("unverified");

  const { rowsPerPage, page } = useSelector(
    (state) => state.pagination
  ).entertainer;

  const {
    data: entertainers = [],
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetEntertainersQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    verificationStatus: type === "verified" ? "approved" : "pending",
  });

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            <span style={{ color: "#6F91C8" }}>Entertainer</span> Accounts
          </h2>
          <RadioGroup
            defaultValue={type}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              refetch();
            }}
          >
            <Stack sx={{ alignItems: "center" }} direction="row">
              <Radio color="info" value="unverified" />
              <Typography variant="caption">Unverified</Typography>
              <Radio color="info" sx={{ ml: 2 }} value="verified" />
              <Typography variant="caption">Verified</Typography>

              <IconButton
                disabled={isFetching || isLoading}
                sx={{ ml: 2 }}
                onClick={refetch}
              >
                <IoMdRefresh />
              </IconButton>
            </Stack>
          </RadioGroup>
          <TablePaginationComponent
            filters={{
              verificationStatus: type === "verified" ? "approved" : "pending",
            }}
            loading={isLoading || isFetching}
            data_size={entertainers.length}
            refetch={refetch}
            resource="entertainer"
          />
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {entertainers.map((entertainer, index) => {
              return (
                <Row
                  entertainer={entertainer}
                  index={index}
                  length={entertainers.length}
                  key={entertainer._id}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
}
