import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, approved: false };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEquipmentList: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/equipment/?limit=${options.limit}&skip=${options.skip}&approved=${options.approved}`,
          method: "GET",
        };
      },
      providesTags: (result = [], error, arg) => [
        { type: "Equipment", id: "LIST" },
      ],
    }),
    getEquipmentSingle: builder.query({
      query: (equipmentID) => {
        return { url: `/equipment/${equipmentID}`, method: "GET" };
      },
    }),

    editEquipment: builder.mutation({
      query: (data) => ({
        url: `/equipment/${data._id}`,
        method: "PATCH",
        body: data.equipment,
      }),
      async onQueryStarted({ _id, equipment }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedEquipment } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData(
              "getEquipmentList",
              params,
              (draft) => {
                const equipment = draft.find(
                  (e) => e._id === updatedEquipment._id
                );
                if (equipment) {
                  Object.keys(updatedEquipment).forEach((key) => {
                    equipment[key] = updatedEquipment[key];
                  });
                }
              }
            )
          );
          dispatch(
            apiSlice.util.updateQueryData(
              "getEquipmentSingle",
              _id,
              (draft) => {
                Object.assign(draft, updatedEquipment);
              }
            )
          );
        } catch {}
      },
      invalidatesTags: [{ type: "Equipment", id: "LIST" }],
    }),
    deleteEquipment: builder.mutation({
      query: (_id) => ({
        url: `/equipment/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Equipment", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEquipmentListQuery,
  useGetEquipmentSingleQuery,
  useEditEquipmentMutation,
  useDeleteEquipmentMutation,
} = extendedApi;
