import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { HiPhone, HiMapPin, HiCalendar } from "react-icons/hi2"; // Ensure correct import for HiPhone and HiMapPin

export default function EventDetails({ open, setOpen, report }) {
  // Helper function to format numbers with commas
  const event = report?.eventDetails || {};
  let { netRevenue, commission, totalSales } = report;
  let eventPromoter = report?.listedByDetails?.eventPromoter;
  let bankingDetails = report?.listedByDetails?.eventPromoter?.bankingDetails;
  // Assume `report` now directly contains the data you provided,
  // otherwise adjust to use state management or props as necessary.

  return (
    <Dialog
      fullWidth
      maxWidth="sm" // Adjusted for potentially wider content
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Event Sales Report </DialogTitle>
      <DialogContent sx={{ maxHeight: "80vh", overflowY: "auto" }}>
        {" "}
        {/* Adjusted for scrolling */}
        {report ? (
          <>
            <Typography variant="h6">{event.title}</Typography>
            <Typography variant="subtitle1">{event.description}</Typography>
            <Typography sx={{ mt: 2 }} variant="body2">
              <HiCalendar />{" "}
              {new Date(event.date).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>
            <Typography variant="body2">
              <HiPhone /> {event.phone}
            </Typography>
            <Typography variant="body2">
              <HiMapPin /> {event.locationEmbed.address}
            </Typography>
            <Typography sx={{ ml: 2 }} variant="body2">
              Venue Number: {event.locationEmbed.venueNumber}
            </Typography>
            <Divider sx={{ my: 2 }} />
            {/* Listed By Information */}
            <Typography variant="h6">Listed By</Typography>
            <Typography variant="subtitle1">
              {eventPromoter?.businessName ||
                `${eventPromoter?.firstName} ${eventPromoter?.lastName}`}
            </Typography>
            {eventPromoter?.representative && (
              <Typography variant="body2">
                Representative: {eventPromoter.representative.firstName}{" "}
                {eventPromoter.representative.lastName}
              </Typography>
            )}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography variant="h6">Banking Details</Typography>
            <Typography variant="body2">
              Bank: {bankingDetails?.bank}
            </Typography>
            <Typography variant="body2">
              Account Number: {bankingDetails?.accountNumber}
            </Typography>
            <Typography variant="body2">
              Account Name: {bankingDetails?.accountName}
            </Typography>
            <Typography variant="body2">
              Branch: {bankingDetails?.branch}
            </Typography>
            <Typography variant="body2">
              Ecocash Number: {bankingDetails?.ecocashNumber}
            </Typography>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Sales Summary
            </Typography>

            <Typography>
              <b>Total Sales</b>
            </Typography>
            <Typography>${totalSales.toFixed(2)}</Typography>
            <Typography sx={{ mb: 0.5 }} variant="caption">
              {report?.event?.tickets.reduce(
                (total, ticket) => total + ticket.sold,
                0
              )}{" "}
              tickets
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <b>Commission</b>
            </Typography>

            <Typography sx={{ mb: 0.5 }}>${commission.toFixed(2)}</Typography>

            <Typography sx={{ mt: 2 }}>
              <b>Net Payout</b>
            </Typography>
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>${netRevenue.toFixed(2)}</Typography>
            </Stack>
            <Divider sx={{ mt: 2, mb: 1 }} />
            {/* Tickets Information */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Tickets
            </Typography>
            {event.tickets.map((ticket) => {
              return (
                <Stack
                  sx={{
                    borderRadius: 3,
                    p: 2,
                    border: 1,
                    borderColor: "rgb(255, 255, 255, 0.05)",
                    mb: 1,
                    borderLeft: 0,
                    borderTop: 0,
                  }}
                >
                  <Stack spacing={4} direction="row">
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="caption">Ticket name</Typography>
                      <Typography sx={{ mb: 1 }}>
                        {ticket.ticketName}
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="caption">Quantity</Typography>
                      <Typography sx={{ mb: 1 }}>{ticket.quantity}</Typography>
                    </Stack>
                  </Stack>
                  <Stack spacing={4} direction="row">
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="caption">Price</Typography>
                      <Typography sx={{ mb: 1 }}>
                        ${ticket.price.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="caption">Sold</Typography>
                      <Typography sx={{ mb: 1 }}>
                        {ticket.sold} | $
                        {(ticket.price * ticket.sold).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </>
        ) : (
          <Typography>Loading event details...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
