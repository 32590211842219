import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuItem from "./menu.item";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { updateDialog } from "../../redux/slices/dialog";
import { updateSnack } from "../../redux/slices/snack";
import { options } from "./config";
import { useLocation } from "react-router-dom";
import { Button, DialogActions, IconButton } from "@mui/material";
import { MdLogout } from "react-icons/md";
import { Menu } from "@mui/icons-material";
const logo = require("../../assets/logo2.png");

export default function Root() {
  const theme = useTheme();

  const dispatch = useDispatch();

  const snack = useSelector((state) => state.snack);

  const dialog = useSelector((state) => state.dialog);

  const handleClose = (event, reason) => {
    dispatch(updateSnack({ open: false, msg: null }));
  };

  const drawerWidth = 280;

  const [value, setValue] = useState([]);

  const handleChange = (newValue) => {
    if (newValue[newValue.length - 1] === "") {
      setValue([""]);
    } else {
      setValue(newValue.filter((a) => a !== ""));
    }
    setMenuOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    handleChange(location.pathname.split("/"));
  }, [location]);

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <Stack
      direction="row"
      sx={{
        background: "rgb(24, 24, 24)",
        flex: 1,
      }}
    >
      <Drawer
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            //  boxShadow: "5px 5px 24px 1px rgba(123, 157, 194, 0.1)",
            borderStyle: "none",
            //  background: "rgb(20, 22, 22)",
            p: 1,
            m: 2,
            borderRadius: 2,
            pb: 3,
          },
        }}
        variant={matches ? "permanent" : "temporary"}
        anchor="left"
      >
        <img src={logo} alt="logo" style={{ width: 150 }} />
        <Divider sx={{ opacity: 0.2, mt: 2 }} />
        <Stack sx={{ ml: 2, mt: 2 }}>
          <Typography variant="caption">Welcome,</Typography>
          <h2 style={{ margin: 0 }}>Admin</h2>
        </Stack>

        <Divider sx={{ mt: 2, mb: 2, opacity: 0.2 }} />
        <List>
          {options.map((data, index) => {
            return (
              <MenuItem data={data} handleChange={handleChange} value={value} />
            );
          })}
        </List>

        <Divider sx={{ mt: 2, mb: 2, opacity: 0.2 }} />

        {false && (
          <Button
            disableElevation
            variant="outlined"
            sx={{ textTransform: "none", height: 40, mr: 2, ml: 2 }}
            startIcon={<MdLogout />}
          >
            Logout
          </Button>
        )}
      </Drawer>
      <Stack id="detail" sx={{ flex: 1 }}>
        {!matches && (
          <Stack
            spacing={2}
            alignItems="center"
            direction="row"
            sx={{ px: 2, alignSelf: "flex-start", pt: 2 }}
          >
            <IconButton
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <Menu />
            </IconButton>
            <img src={logo} alt="logo" style={{ width: 100 }} />
          </Stack>
        )}
        <Outlet />
      </Stack>
      <Snackbar
        open={snack.open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snack.msg}
      />
      <Dialog
        onClose={() => {
          dispatch(updateDialog({ open: false, msg: null }));
        }}
        PaperProps={{
          sx: {
            background: "rgba(252, 233, 232)",
          },
        }}
        open={dialog.open}
      >
        <DialogTitle>Sorry, an error occurred.</DialogTitle>
        <DialogContent sx={{ pb: 0, mb: 0 }}>
          <Alert
            sx={{ width: 320, borderRadius: 2, mb: 0 }}
            variant="standard"
            severity="error"
          >
            {dialog.msg}
          </Alert>
        </DialogContent>
        <DialogActions sx={{ mt: 0, pt: 0 }}>
          <Button
            onClick={() => {
              dispatch(updateDialog({ open: false, msg: null }));
            }}
            color="warning"
            sx={{ textTransform: "none", m: 1 }}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
