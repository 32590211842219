// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./auth-header";
import { constants } from "../config/constants";

let authorization = authHeader();

const baseQuery = fetchBaseQuery({
  baseUrl: constants.apiUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set("authorization", authorization);
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      //  api.dispatch(setUser({ user: null, loaded: true }));
    } else {
      console.log(result);
      let msg = "";
      if (result.error.data) {
        msg = result.error.data.error;
      } else {
        msg =
          "Request could not be fulfilled. Please check your internet connection.";
      }
      //api.dispatch(updateDialog({ open: true, msg }));
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  tagTypes: [
    "Booking",
    "User",
    "Category",
    "Schedule",
    "Entertainer",
    "User",
    "Equipment",
    "Event",
    "Payout",
    "Service",
  ],
  endpoints: () => ({}),
});
