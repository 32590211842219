import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Booking from "./modal/view";

export default function Row({ eventPromoter, index, length }) {
  const checked = true;

  const { phone, email } = eventPromoter;
  let { name, firstName, lastName, businessName, city, representative } =
    eventPromoter.eventPromoter;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <Stack
          key={eventPromoter._id}
          sx={{
            //   boxShadow: "5px 5px 24px 1px rgba(123, 157, 194, 0.1)",
            borderRadius: 0,
            mt: 1,
            p: 2,
            background: "#1B1B1B",
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: "solid",
            alignItems: "center",
          }}
          direction="row"
        >
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography
              onClick={() => {
                setOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              {businessName || `${firstName} ${lastName}`}
            </Typography>
            <Typography variant="caption">
              {index + 1} of {length}
            </Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>{`${representative?.firstName || "N/A"} ${
              representative?.lastName || ""
            }`}</Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>{phone}</Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>{email}</Typography>
          </Stack>
        </Stack>
      </Fade>
      {open && (
        <Booking open={open} _id={eventPromoter._id} setOpen={setOpen} />
      )}
    </>
  );
}
