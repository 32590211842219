import {
  HiCalendar,
  HiBanknotes,
  HiSparkles,
  HiWrench,
  HiOutlineViewColumns,
  HiBuildingStorefront,
  HiCreditCard,
  HiFire,
  HiStar,
  HiUser,
} from "react-icons/hi2";

import { BsTv } from "react-icons/bs";
import { MdPiano } from "react-icons/md";

const iconSize = 20;

const options = [
  {
    id: "",
    title: "Bookings",
    icon: <HiCalendar size={iconSize} />,
  },
  {
    id: "events",
    title: "Events",
    icon: <HiSparkles size={iconSize} />,
  },
  {
    id: "sales",
    title: "Sales",
    icon: <HiCreditCard size={iconSize} />,
  },
  {
    id: "verifications",
    title: "Entertainers",
    icon: <HiFire size={iconSize} />,
  },
  {
    id: "promoters",
    title: "Promoters",
    icon: <HiStar size={iconSize} />,
  },
  {
    id: "venues",
    title: "Venues",
    icon: <HiBuildingStorefront size={iconSize} />,
  },
  {
    id: "users",
    title: "End-users",
    icon: <HiUser size={iconSize} />,
  },
  {
    id: "services",
    title: "Services",
    icon: <MdPiano size={iconSize} />,
  },
  {
    id: "equipment",
    title: "Equipment",
    icon: <BsTv size={iconSize} />,
  },
  {
    id: "payouts",
    title: "Payouts",
    icon: <HiBanknotes size={iconSize} />,
  },

  {
    id: "charges",
    title: "Charge Sheet",
    icon: <HiOutlineViewColumns size={iconSize} />,
  },
  {
    id: "settings",
    title: "Settings",
    icon: <HiWrench size={iconSize} />,
  },
];

const hidden = [
  {
    id: "admins",
    title: "Administrators",
    icon: <HiWrench size={iconSize} />,
  },
];

export { options };
