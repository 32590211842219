import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiShoppingBag, HiPhone, HiMapPin } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetBookingQuery } from "../../../../api/endpoints/booking";
import Cart from "./cart";
import ProofOfPayment from "./proofOfPayment";

export default function Booking({ open, setOpen, _id }) {
  const {
    data: booking = null,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetBookingQuery(_id);

  let client;
  let venue;

  if (booking) {
    client = booking.client;
    venue = client.venue;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        Booking Details {"("}
        {booking ? booking.bookingNumber : "loading"}
        {")"}
      </DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {booking && (
          <Grow in={!isLoading}>
            <div>
              {venue && (
                <Stack sx={{ mt: 1 }}>
                  <Stack>
                    <Typography>
                      <b>{venue.name}</b>
                    </Typography>
                    <Chip
                      size="small"
                      color="info"
                      sx={{ ml: 0.5, alignSelf: "flex-start" }}
                      label="Venue"
                    />
                  </Stack>
                  <Typography>
                    Representative: {venue.representative.firstName}{" "}
                    {venue.representative.lastName}
                  </Typography>
                </Stack>
              )}
              {!venue && (
                <Stack sx={{ mt: 1, mb: 1 }}>
                  <Stack>
                    <Typography>
                      <b>
                        {client.firstName} {client.lastName}
                      </b>
                    </Typography>
                    <Chip
                      size="small"
                      color="info"
                      sx={{ ml: 0.5, alignSelf: "flex-start" }}
                      label="Personal Account"
                    />
                  </Stack>
                </Stack>
              )}
              <Stack sx={{ mt: 0 }} direction="row">
                <HiPhone style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>Phone: {client.phone}</Typography>
                </Stack>
              </Stack>

              <Stack sx={{ mt: 0 }} direction="row">
                <HiMapPin style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>Street: {booking.location.street}</Typography>
                  <Typography>
                    House Number: <b>{booking.location.houseNumber}</b>
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
              <Stack sx={{ alignItems: "center", mt: 2 }} direction="row">
                <HiShoppingBag />

                <Typography sx={{ ml: 2 }}>
                  Booked dates{"(" + booking.acceptedDates.length + ")"}
                </Typography>
              </Stack>
              {booking?.proofOfPayment && <ProofOfPayment booking={booking} />}
              <Cart booking={booking} />
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
