import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => {
        return { url: "/admin/users/end-users", method: "GET" };
      },
      providesTags: (result = [], error, arg) => [
        "User-Admin",
        ...result.map(({ _id }) => ({ type: "User-Admin", _id })),
        { type: "User-Admin", id: "LIST" },
      ],
    }),

    getUser: builder.query({
      query: (userID) => {
        return { url: `/users/single/${userID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "User-Admin", id: arg }],
    }),

    addNewUser: builder.mutation({
      query: (user) => ({
        url: "/admin/users/organization",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User-Admin"],
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: `/admin/users/${data._id}`,
        method: "PATCH",
        body: data.user,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "User-Admin", _id: arg.id },
      ],
    }),
    getResourceCount: builder.query({
      query: (options) => {
        return {
          url: `/resources/count/${options.resource}${
            options.filters ? "?filters=" + JSON.stringify(options.filters) : ""
          }`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useAddNewUserMutation,
  useEditUserMutation,
  useGetResourceCountQuery,
} = extendedApi;
